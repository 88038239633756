import axios from "axios";
import router from "@/router";
import { Toast } from 'vant';
//  http://api.wwhao888.vip/api http://api.box.yanshi888.vip/api 103.100.62.133/api
let http = axios.create({
    baseURL: 'https://gold.blgg888.com/api/',
    timeout: 10000,
});

// 请求拦截器
http.interceptors.request.use(config => {
    // 根据请求方法和参数设置 Content-Type
    if (config.url === '/image/upload') {
        config.headers['Content-Type'] = 'multipart/form-data'
    } else {
        config.headers['Content-Type'] = 'application/json'
    }
    config.headers.token = window.localStorage.getItem("__token__")
    return config;
});

// 响应拦截器
http.interceptors.response.use(
    Response => {
        if (Response.data.code === -1) {
            window.localStorage.removeItem("__token__");
            router.push({ path: '/lodin' })
            return Promise.reject(Response.data)
        } else if (Response.data.code === 0) {
            Toast(Response.data.msg)
            return Promise.reject(Response.data)
        } else {
            return Response.data;
        }
    },
    error => {
        console.error(error, "error");
        return error;
    }
);

export default http