<!-- 登录页面 -->
<template>
    <div class='lodin'>
        <p><b>会员登陆</b></p>
        <div class="content">
            <div class="img">
                <img :src="url+logo">
            </div>
            <van-form @submit="onSubmit">
                <van-field v-model="userobj.mobile" name="mobile" label="手机号"
                    placeholder="请输入手机号" :rules="[{ required: false, message: '手机好格式错误' }]" />
                <div>
                    <van-field v-model="userobj.password" type="password" name="password"
                        label="密码" placeholder="请输入密码" :rules="[{ required: false, message: '密码格式错误' }]" />
                    <router-link tag="div" class="pass" to="/ChangePassword">忘记密码?</router-link>
                </div>
                <van-field v-model="code" label="图形验证码" placeholder="请输入下方计算结果"
                    :rules="[{ required: false, message: '图形验证码不能为空' }]" />
                <div>
                    <p>计算公式</p>
                    <canvas ref="canvas" width="200" height="50" @click="randomNumber"></canvas>
                </div>
                <van-field v-model="userobj.paint_code" name="paint_code" label="店号" placeholder="请输入店号"
                    :rules="[{ required: false, message: '店名不能为空' }]" />
                <div>
                    <van-field v-model="userobj.sms_code" name="sms_code" label="验证码(仅店长需要)" placeholder="请输入验证码"
                        :rules="[{ required: false, message: '验证码错误' }]" />
                    <div class="pass pass1" >{{ cod }}</div>
                </div>
                <div style="margin: 16px;">
                    <van-button round block type="info" native-type="submit">{{ sub }}</van-button>
                </div>
            </van-form>
            <div class="register">
                <p>没有账号？<router-link to="/register" tag="span">立即注册</router-link></p>
            </div>
            <div class="server">
                更多服务器
            </div>
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { url } from '@/index';
import { lodin } from '@/api/lodin.js'
import { post } from '@/api/home'
import loading from '@/components/loading/LoadingCom.vue'

export default {
    name: 'loding',
    //import引入的组件需要注入到对象中才能使用
    components: { loading },
    data() {
        //这里存放数据
        return {
            code: '',
            cod: '获取验证码',
            canvas: '',
            canvae: '',
            userobj: {
                mobile: '',
                password: '',
                paint_code: '',
                sms_code: ''
            },
            flag: '',
            flage: true,
            sub:'登录',
            logo:'',
            url
        };
    },
    //计算属性 类似于data概念
    computed: {
    },
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        canva() {
            let can1 = Math.floor(Math.random() * 10)
            let can2 = Math.random() < .5 ? '+' : '-'
            let can3 = Math.floor(Math.random() * 10)
            this.canvas = can1 + can2 + can3
            if (can2 === '+') this.canvae = can1 + can3; else if (can2 === '-') this.canvae = can1 - can3
        },
        randomColor() {
            const colorlist=[`rgb(76,117,167)`,`rgb(102,143,81)`,`rgb(65,117,121)`,`rgb(64,71,121)`,`rgb(41,41,41)`,`rgb(26,26,26)`,`rgb(149,121,191)`,`rgb(177,116,150)`,`rgb(114,197,126)`]
            return colorlist[Math.floor(Math.random()*9)];
        },
        randomAngle() {
            return Math.random() * .9 - 0.45; // 随机角度范围为 -0.45 到 0.45 弧度
        },
        pixel(ctx, canvas) {
            for (let i = 0; i < 30; i++) {
                // const random = Math.random()
                // let width = 0
                // if (random <= 0.1) {
                //     width = Math.random() * canvas.width * .3
                // } else if (random > .1 && random <= .4) {
                //     width = Math.random() * canvas.width * .3 + canvas.width * .3
                // } else {
                //     width = Math.random() * canvas.width * .4 + canvas.width * .6
                // }
                const x = Math.floor(Math.random() * canvas.width);
                const y = Math.floor(Math.random() * canvas.height);
                const color = this.randomColor();
                ctx.beginPath();
                ctx.fillStyle = color;
                ctx.arc(x, y, .5, 0, 2 * Math.PI);
                ctx.fillRect(x, y, .5, .5);
                ctx.fill();
            }
        },
        figure(ctx) {
            ctx.font = '300 200px Arial';
            this.canva()
            const can = this.canvas + '=';
            can.split('').forEach((value, index) => {
                const number = value;
                const x = 10 + index * 20;
                const y = 30;
                const angle = this.randomAngle();
                const color = this.randomColor();
                ctx.fillStyle = color;
                ctx.save();
                ctx.translate(x, y);
                ctx.rotate(angle);
                ctx.scale(0.1, 0.1);
                ctx.fillText(number, 0, 0);
                ctx.restore();
            })
        },
        randomNumber() {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            // 设置画布尺寸
            canvas.width = 200;
            canvas.height = 40;
            // 绘制白色背景
            ctx.fillStyle = '#fff';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            // 描绘像素点
            this.pixel(ctx, canvas);
            // 绘制数字
            this.figure(ctx)
        },
        onSubmit(values) {
            if(!values.mobile){
                this.$toast('请输入手机号')
                return;
            }
            if(!values.mobile.match(/^1[2-9]\d{9}$/)){
                this.$toast('请输入正确手机号')
                return;
            }
            if(!values.password){
                this.$toast('请输入密码')
                return;
            }
            if(!values.paint_code){
                this.$toast('请输入店号')
                return;
            }
            if (this.code == this.canvae) {
                this.sub='登录中'
                lodin(values).then(data => {
                    if (data) {
                        localStorage.setItem('__token__', data.data.user_info.login_token)
                        localStorage.setItem('__user__', JSON.stringify(data.data.paint_id))
                        localStorage.setItem('__users__', JSON.stringify(data.data.user_info))
                        let dz=''
                        post('/user/getUserAddress').then(data=>{
                            console.log(data)
                            dz=data.data
                            if(!dz){
                                this.$router.push({ path: 'home',query:{index:1} })
                            }else{
                                this.$router.push({ path: 'home',query:{index:0} })
                            }
                        })
                    }
                }).catch(() => { }).finally(() => this.sub='登录')
            } else {
                this.randomNumber()
                this.$toast('图形验证码错误')
            }
        },
        // 验证码
        codes() {
            if (this.flage) {
                if (this.userobj.mobile) {
                    this.flage = false
                    let datas = 99
                    this.cod = datas
                    post('/user/sendSmsCode', { "mobile": this.userobj.mobile }).then(data => {
                    }).catch(error => { })
                    let a = setInterval(() => {
                        if (datas != 0) {
                            datas--;
                            this.cod = datas
                        } else {
                            this.flage = true
                            this.cod = '获取验证码'
                            clearInterval(a)
                        }
                    }, 1000)
                } else {
                    this.$toast('手机号不能为空')
                }
            }
        }
    },
    beforeCreate() { }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        if (localStorage.getItem('__token__')) {
            this.$router.push({ path: 'home' })
        }
        post('/index/logo').then(res=>{
            this.logo=res.data
        })
    },
    beforeMount() { }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        this.randomNumber()
    },
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，激活
    deactivated() { }, //如果页面有keep-alive缓存功能，非激活
}
</script>
<style lang="less">
.lodin {
    width: 100%;
    font-weight: bold;
    color: #000;

    &>p {
        width: 100%;
        height: 4.4rem;
        text-align: center;
        font-size: 1.6rem;
        color: #000;
        line-height: 4.4rem;
        background-color: rgb(248, 248, 248);
        display: block;
    }
    &>.content {
        width: 100%;
        margin-top: 6rem;

        &>.img {
            width: 100%;
            height: 13rem;
            display: flex;
            align-items: center;
            justify-content: center;

            &>img {
                width: 7rem;
                height: 7rem;
            }
        }

        &>form {
            width: 97%;
            margin: 0 auto;
            font-weight: bold;
            [class="van-cell__title van-field__label"]{
                color: #000;
            }

            &>div:nth-child(6){
                .van-field__label{
                    width: 14rem;
                }
            }
            &>div:nth-last-child(1){
                border-bottom: 0;
            }
            &>div:nth-last-child(2){
                ::placeholder {
                   font-size: 1.2rem;
                }
            }
            &>div {
                width: 100%;
                display: flex;
                box-sizing: border-box;
                border-bottom: 1px solid #757575;
                ::placeholder {
                   color: #000;
                }

                &>.pass {
                    min-width: 7rem;
                    height: 2rem;
                    border-radius: 1.5rem;
                    background-color: #fc4141;
                    font-size: 1.4rem;
                    color: #fff;
                    line-height: 2rem;
                    text-align: center;
                    margin-top: .8rem;
                    padding: .5rem 1rem;
                    margin-right: 1.6rem;
                }

                &>.pass1 {
                    background-color: #fc4141;
                    background-image: none;
                }

                &>button {
                    width: 34.3rem;
                    margin: 2rem auto 0;
                    height: 4.2rem;
                    border: 0;
                    outline: 0 !important;
                    font-size: 1.6rem;
                    background-color: #fc4141;
                    box-shadow: 0px 0px 0px 0px #fc414190;
                    border-radius: .5rem;
                    color: #fff;
                    text-align: center;
                    line-height: 4.2rem;
                }
            }

            &>div:nth-child(4) {
                font-size: 1.5rem;
                align-items: center;
                justify-content: space-between;
                padding: 0 0 0 1.6rem;
            }

            &>div:nth-child(7) {
                margin: 0 !important;
            }
        }

        &>.register {
            width: 100%;
            height: 5.9rem;
            line-height: 5.9rem;
            text-align: center;
            background-color: #fff;

            span {
                color: #fc4141;
            }
        }

        &>.server {
            width: 100%;
            height: 3.577rem;
            line-height: 3.577rem;
            background-color: #f8f8f8;
            text-align: center;
        }
    }
}</style>