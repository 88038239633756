import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import { Icon } from 'vant';
import { Uploader } from 'vant';
import { Popup } from 'vant';
import { Toast } from 'vant';
import { Area } from 'vant';
import { ImagePreview } from "vant";
import VueClipboard from 'vue-clipboard2'
import { List } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Button } from 'vant';
import { PullRefresh } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Checklist } from 'mint-ui';
import { DatetimePicker } from 'vant';
import { Switch } from 'vant';
import { Image as VanImage } from 'vant';
import { Swipe, SwipeItem } from 'vant';

Vue.use(Swipe).use(SwipeItem)
Vue.use(VanImage);
Vue.use(Switch);
Vue.use(DatetimePicker);
Vue.component(Checklist.name, Checklist);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(PullRefresh);
Vue.use(Button);
Vue.use(Form);
Vue.use(Field);
Vue.use(List);
Vue.use(ImagePreview)
Vue.use(VueClipboard)
Vue.use(Area);
Vue.use(Toast);
Vue.use(Popup);
Vue.use(Uploader);
Vue.use(Icon);

import '@/assets/base.css'
import 'vant/lib/index.css'
import 'mint-ui/lib/style.css'

Vue.config.productionTip = false


router.afterEach((to, from, next) => {
    window.scrollTo(0, 0)
})

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')