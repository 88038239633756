import VueRouter from 'vue-router'
import Vue from 'vue'

Vue.use(VueRouter)

import lodin from '@/view/land/Lodin'
import ShellFrame from '@/view/ShellFrame'
import HomeView from '@/view/home/HomeView'


const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location) {
    return routerPush.call(this, location).catch(err => {})
};

let routes = [{
    path: '/',
    redirect: '/lodin',
}, {
    path: '/lodin',
    component: lodin,
    meta: {
        tiles: '会员登录',
    },
}, {
    path: '/register',
    meta: {
        tiles: '会员注册',
    },
    component: () =>
        import ('@/view/land/Subpages/Register')
}, {
    path: '/ChangePassword',
    meta: {
        tiles: '修改密码',
    },
    component: () =>
        import ('@/view/land/Subpages/ChangePassword')
}, {
    path: '/ShellFrame',
    component: ShellFrame,
    children: [{
        path: '/home',
        meta: {
            tiles: '首页',
        },
        component: HomeView
    }, {
        path: '/record',
        meta: {
            tiles: '全部记录',
        },
        component: () =>
            import ('@/view/RecordView')
    }, {
        path: '/distribution',
        meta: {
            tiles: '分销中心',
        },
        component: () =>
            import ('@/view/distribution/DistributionView')
    }, {
        path: '/user',
        meta: {
            tiles: '个人中心',
        },
        component: () =>
            import ('@/view/user/UserView')
    }, {
        path: '/Convertibility',
        meta: {
            tiles: '兑换订单',
        },
        component: () =>
            import ('@/view/user/Subpages/ConvertibilityView')
    }, {
        path: '/Putaway',
        meta: {
            tiles: '上架费商品兑换',
        },
        component: () =>
            import ('@/view/user/Subpages/PutawayView')
    }, {
        path: '/PersonalData',
        meta: {
            tiles: '个人资料',
        },
        component: () =>
            import ('@/view/user/Subpages/PersonalData')
    }, {
        path: '/Location',
        meta: {
            tiles: '我的地址',
        },
        component: () =>
            import ('@/view/user/Subpages/LocationView')
    }, {
        path: '/WithdrawalDeteils',
        meta: {
            tiles: '提现明细',
        },
        component: () =>
            import ('@/view/distribution/Subpages/WithdrawalDeteils')
    }, {
        path: '/Commission',
        meta: {
            tiles: '佣金明细',
        },
        component: () =>
            import ('@/view/distribution/Subpages/CommissionView')
    }, {
        path: '/WithdrawalRecords',
        meta: {
            tiles: '提现记录',
        },
        component: () =>
            import ('@/view/distribution/Subpages/WithdrawalRecords')
    }, {
        path: '/Performance',
        meta: {
            tiles: '业绩订单',
        },
        component: () =>
            import ('@/view/distribution/Subpages/PerformanceView')
    }, {
        path: '/Expand',
        meta: {
            tiles: '推广订单',
        },
        component: () =>
            import ('@/view/distribution/Subpages/ExpandView')
    }, {
        path: '/Fans',
        meta: {
            tiles: '我的粉丝',
        },
        component: () =>
            import ('@/view/distribution/Subpages/FansView')
    }, {
        path: '/Encyclopedia',
        meta: {
            tiles: '文化百科',
        },
        component: () =>
            import ('@/view/home/AppointmentAcitivities/Encyclopedia')
    }, {
        path: '/Activity',
        meta: {
            tiles: '十一活动',
        },
        component: () =>
            import ('@/view/home/AppointmentAcitivities/ActivityView')
    }, {
        path: '/Schedule',
        meta: {
            tiles: '活动进度',
        },
        component: () =>
            import ('@/view/home/AppointmentAcitivities/ScheduleView')
    }, {
        path: '/PoCourt',
        meta: {
            tiles: '锦秀宝阁',
        },
        component: () =>
            import ('@/view/home/AppointmentAcitivities/PoCourt')
    }, {
        path: '/Reservation',
        meta: {
            tiles: '推广订单',
        },
        component: () =>
            import ('@/view/home/AppointmentAcitivities/Reservation')
    }, {
        path: '/Operate',
        meta: {
            tiles: '操作台',
        },
        component: () =>
            import ('@/view/distribution/Subpages/OperateView')
    }, {
        path: '/Rosewood',
        meta: {
            tiles: '红木管理',
        },
        component: () =>
            import ('@/view/distribution/Subpages/operate/RosewoodView'),
    }, {
        path: '/Order',
        meta: {
            tiles: '订单管理',
        },
        component: () =>
            import ('@/view/distribution/Subpages/operate/OrderManagement'),
    }, {
        path: '/Details',
        meta: {
            tiles: '上架费商品兑换',
        },
        component: () =>
            import ('@/view/user/Subpages/DetailsView'),
    }, {
        path: '/Audit',
        meta: {
            tiles: '上架审核',
        },
        component: () =>
            import ('@/view/distribution/Subpages/operate/AuditView')
    }, {
        path: '/Withdrawal',
        meta: {
            tiles: '提现审核',
        },
        component: () =>
            import ('@/view/distribution/Subpages/operate/WithdrawalAudit'),
    }, {
        path: '/Performances',
        meta: {
            tiles: '店业绩',
        },
        component: () =>
            import ('@/view/distribution/Subpages/operate/Performance'),
    }, {
        path: '/Transfer',
        meta: {
            tiles: '转移确认',
        },
        component: () =>
            import ('@/view/distribution/Subpages/operate/TransferConfirmation'),
    }, {
        path: '/Convert',
        meta: {
            tiles: '上架费兑换',
        },
        component: () =>
            import ('@/view/distribution/Subpages/operate/ConvertView'),
    }, {
        path: '/PickUpGoods',
        meta: {
            tiles: '上架费提货',
        },
        component: () =>
            import ('@/view/distribution/Subpages/operate/PickUpGoods'),
    }, {
        path: '/Users',
        meta: {
            tiles: '个人中心',
        },
        component: () =>
            import ('@/view/distribution/Subpages/operate/UserView')
    }, {
        path: '/MbpApp',
        meta: {
            tiles: '会员预约',
        },
        component: () =>
            import ('@/view/distribution/Subpages/operate/MbpAppointment'),
    }, {
        path: '/AppManag',
        meta: {
            tiles: '店预约管理',
        },
        component: () =>
            import ('@/view/distribution/Subpages/operate/AppManagement'),
    }]
}]


let router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.tiles
    next()
})

export default router