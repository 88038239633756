<!-- 首页 -->
<template>
    <div class='HomeView'>
        <div class="img">
            <van-swipe :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="value in list.banner_list" :key="value">
                    <img :src="url + value" alt="网络错误">
                </van-swipe-item>
            </van-swipe>
        </div>
        <div class="frame tally" :style="'background: url('+url+list.order_bg+');background-size: 100% 100%;'">
            <p>记账列表</p>
            <main>
                <div @click="records(2)">
                    <img :src="url+list.order_img[0]">
                    <p>等待记账</p>
                </div>
                <div @click="records(3)">
                    <img :src="url+list.order_img[1]">
                    <p>代收记账</p>
                </div>
                <div @click="records(4)">
                    <img :src="url+list.order_img[2]">
                    <p>委托记账</p>
                </div>
            </main>
        </div>
        <img :src="url+list.waist_img">
        <div class="frame activity" :style="'background: url('+url+list.active_bg+');background-size: 100% 100%;'">
            <p>预约活动</p>
            <main>
                <router-link tag="div" to="/PoCourt">
                    <img :src="url+list.active_img[0]">
                    <p>{{ paint_name }}</p>
                </router-link>
                <router-link tag="div" to="/Activity">
                    <img :src="url+list.active_img[1]">
                    <p>十一活动</p>
                </router-link>
                <router-link tag="div" to="/Reservation">
                    <img :src="url+list.active_img[2]">
                    <p>预约</p>
                </router-link>
            </main>
        </div>
        <div class="frame encyclopedia" :style="'background: url('+url+list.intro_bg+');background-size: 100% 100%;'">
            <p>文化百科</p>
            <main v-if="home.length !== 0">
                <router-link tag="div" :to="{ path: '/Encyclopedia', query: { index: home[0].title } }">
                    <img :src="url + home[0].img">
                    <p>{{ home[0].title }}</p>
                </router-link>
                <router-link tag="div" :to="{ path: '/Encyclopedia', query: { index: home[1].title } }">
                    <img :src="url + home[1].img">
                    <p>{{ home[1].title }}</p>
                </router-link>
                <router-link tag="div" :to="{ path: '/Encyclopedia', query: { index: home[2].title } }">
                    <img :src="url + home[2].img">
                    <p>{{ home[2].title }}</p>
                </router-link>
            </main>
        </div>
        <img :src="url+list.bottom_img">
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { post } from '@/api/home'
import { url } from '@/index'
import { Dialog } from 'vant';

export default {
    //import引入的组件需要注入到对象中才能使用
    name: 'HomeView',
    components: {},
    data() {
        //这里存放数据
        return {
            home: '',
            list: '',
            paint_name: '',
            url
        };
    },
    //计算属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        records(index) {
            this.$router.push({
                path: '/record',
                query: {
                    index
                }
            })
        }
    },
    beforeCreate() { }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        // post('index/getUserSetting').then((data)=>{
        //     if(data.data==1){
        //         this.$toast('未设置支付信息')
        //     }else if(data.data==2){
        //         this.$toast('未设置收货地址')
        //     }
        // }).catch((error)=>{})
        if (this.$route.query.index == 1) {
            Dialog.alert({
                message: '请添加收货地址',
            }).then(() => {
                this.$router.push({path:'/Location'})
            });
        }
        post('index/index').then(data => {
            this.home = data.data.article;
            this.list = data.data.config
			console.log(this.list);
        }).catch((error) => { })
        this.paint_name = JSON.parse(localStorage.getItem('__user__')).paint_name
    },
    beforeMount() { }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，激活
    deactivated() { }, //如果页面有keep-alive缓存功能，非激活
}
</script>
<style lang="less">
.HomeView {
    width: 100%;
    padding-bottom: 7.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    color: #000;

    &>.img {
        width: 95%;
        height: 18.4rem;
        margin-bottom: 2rem;

        &>.van-swipe {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    &>img:nth-child(3) {
        width: 100%;
        height: 9.4rem;
        margin: 2rem 0;
    }

    &>img:nth-child(6) {
        width: 100%;
        height: 8.8rem;
        margin: 2rem 0 0;
    }

    &>.tally {
        background-image: url('@/assets/images/home/bg1.jpg');
    }

    &>.activity {
        background-image: url('@/assets/images/home/bg2.jpg');
        margin-bottom: 1rem;
    }

    &>.encyclopedia {
        background-image: url('@/assets/images/home/bg3.jpg');
    }

    &>.frame {
        width: 100%;
        height: 18.1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-size: 100% 100%;

        &>p {
            font-size: 18px;
            font-weight: bold;
        }

        &>main {
            width: 80%;
            display: flex;
            justify-content: space-around;

            &>div {
                width: 9.9rem;
                height: 11rem;
                display: flex;
                flex-direction: column;
                align-items: center;

                &>img {
                    width: 9rem;
                    height: 9rem;
                }

                &>p {
                    line-height: 2.5rem;
                    text-align: center;
                    font-size: 1.3rem;
                }
            }
        }
    }
}</style>