<!-- 展示框架 -->
<template>
    <div class='ShellFrame'>
        <keep-alive :include='includes'>
            <router-view></router-view>
        </keep-alive>
        <footer v-if="fage" ref="app">
            <router-link tag="div" to="/home" :class="this.$route.path === '/home'?'div':''">
                首页
            </router-link>
            <router-link tag="div" to="/record" :class="this.$route.path === '/record'?'div':''">
                订单记录
            </router-link>
            <router-link tag="div" to="/distribution" :class="this.$route.path === '/distribution'?'div':''">
                记账中心
            </router-link>
            <router-link tag="div" to="/user" :class="this.$route.path === '/user'?'div':''">
                个人中心
            </router-link>
        </footer>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            includes: ['DetailsView', 'HomeView'],
            urllist: ['/AppManag', '/MbpApp', '/Users', '/PickUpGoods', '/Convert', '/Transfer', '/Performances', '/Withdrawal', '/Audit', '/Details', '/Order', '/Rosewood', '/Operate', '/Reservation', '/PoCourt', '/Schedule', '/Activity', '/Encyclopedia', '/Fans', '/Expand', '/Performance', '/WithdrawalRecords', '/Convertibility', '/Putaway', '/PersonalData', '/Location', '/WithdrawalDeteils', '/Commission']
        };
    },
    //计算属性 类似于data概念
    computed: {
        fage() {
            return !this.urllist.some(value => this.$route.path === value);
        }
    },
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
    },
    beforeCreate() { }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    beforeMount() { }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，激活
    deactivated() { }, //如果页面有keep-alive缓存功能，非激活
}
</script>
<style lang="less">
.ShellFrame {
    width: 100%;

    &>footer {
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 1200px;
        height: 7.4rem;
        padding-top: 22px;
        padding-bottom: 18px;
        box-sizing: border-box;
        border-top: .1rem solid #eee;
        background-color: #fff;
        display: flex;
        // justify-content: space-around;

        &>.div{
            color: red;
        }

        &>div:nth-last-child(1){
            border-right: 0;
        }

        &>div {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.8rem;
            font-weight: bold;
            border-right: 1px solid #000;
        }
    }
}</style>